import * as React from 'react'
import { Options } from 'react-markdown/lib/ast-to-react'
import ReactMarkdown from 'react-markdown'
import NextLink from 'next/link'
import rehypeRaw from 'rehype-raw'
import { Media } from './media'
import { P } from './p'
import { UL, LI, OL } from './list'
import { Table, Head, Body, Row, HeadCell, DataCell } from './table'

export type MarkdownProps = {
  children: string
  components?: Options['components'] | any
}

export const Markdown = ({ children, components }: MarkdownProps) => {
  if (!children) {
    return null
  }

  const content = children.replace(/[ ]{2,}/g, ' ')

  return (
    <ReactMarkdown
      components={{
        p: P,

        ul: UL,
        ol: OL,
        li: LI,

        img: Media,

        table: Table,
        tbody: Body,
        thead: Head,
        tr: Row,
        th: HeadCell,
        td: DataCell,

        a: ({ children, href }) => {
          let superscript
          if (typeof children[0] === 'object' && (children[0] as React.ReactElement)?.type === 'sup') {
            superscript = true
          } else {
            superscript = false
          }

          if (href) {
            const isExternal = /^(http|www|mailto|tel)/.test(href)
            return (
              <NextLink
                href={href}
                target={isExternal ? '_blank' : ''}
                rel={isExternal ? 'noopener external' : ''}
                className={
                  superscript
                    ? 'text-selphOrange-500'
                    : 'underline decoration-selphOrange-500 decoration-2 hover:text-selphOrange-500'
                }
              >
                {children}
              </NextLink>
            )
          } else {
            return <span>{children}</span>
          }
        },

        code: ({ children }: { children: React.ReactNode }) => <>{children}</>,

        pre: ({ children }: { children: React.ReactNode }) => <p className="whitespace-pre-wrap">{children}</p>,

        em: ({ children }) => {
          if (typeof children[0] === 'string' && children[0].startsWith('^')) {
            return <sup>{children[0].substring(1)}</sup>
          }
          if (typeof children[0] === 'string' && children[0].startsWith('~')) {
            return <sub>{children[0].substring(1)}</sub>
          }
          return <em>{children}</em>
        },

        ...components,
      }}
      rehypePlugins={[rehypeRaw]}
    >
      {content}
    </ReactMarkdown>
  )
}

Markdown.displayName = 'Markdown'

export default Markdown
