import { stateBGColors } from '../chart-colors'
import { useUi, type Breakpoints } from '../use-ui'
import { TableSizes, useTable, TableBackgroundColors } from './table'

export type DAligns = 'start' | 'center' | 'end'
export type DTextColors = 'white' | 'black' | TableBackgroundColors

export const sizes: { [key in TableSizes]: string } = {
  xs: 'py-1 px-2',
  small: 'py-1 px-3',
  medium: 'py-3 px-4',
  large: 'py-4 px-5',
  xl: 'py-5 px-6',
}

export const aligns: { [key in DAligns]: string } = {
  start: 'text-left',
  center: 'text-center',
  end: 'text-right',
}

export type DProps = {
  align?: Breakpoints<DAligns>
  className?: string
  textColor?: string
  children?: React.ReactNode
} & Omit<
  React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>,
  'className' | 'align'
>

export const D = ({ align = 'start', className, children, ...props }: DProps) => {
  const { size, backgroundColor } = useTable()

  const textColors: { [key in DTextColors]: string } = {
    alternate: '',
    black: 'text-selphBlack',
    white: 'text-selphWhite-500',
    transparent: 'text-selphBlack',
    ...stateBGColors,
  }

  const ui = useUi({
    styles: {
      align: { options: aligns, selected: align },
      textColors: { options: textColors, selected: backgroundColor },
      sizes: { options: sizes, selected: size },
    },
    name: 'Table.D',
    className,
  })

  return (
    <td {...props} className={ui.className}>
      {children}
    </td>
  )
}

D.displayName = 'Table.D'

export default D
