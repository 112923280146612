import { stateBGColors } from '../chart-colors'
import { useUi } from '../use-ui'
import { TableBackgroundColors, TableDivideColors, useTable } from './table'

export type BodyProps = {
  className?: string
  children: React.ReactNode
} & Omit<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>,
  'className' | 'children'
>

export const Body = ({ children, className, ...props }: BodyProps) => {
  const { backgroundColor, divideColor } = useTable()

  const divideColors: { [key in TableDivideColors]: string } = {
    transparent: 'divide-transparent',
    light: 'divide-selphWhite-500',
    dark: 'divide-selphBlack',
  }

  const tableBackgroundColors: { [key in TableBackgroundColors]: string } = {
    transparent: '',
    alternate: '',
    ...stateBGColors,
  }

  const ui = useUi({
    styles: {
      divideColor: { options: divideColors, selected: divideColor },
      backgroundColor: { options: tableBackgroundColors, selected: backgroundColor },
    },
    name: 'Table.Body',
    className: `divide-y ${className}`,
  })

  return (
    <tbody className={ui.className} {...props}>
      {children}
    </tbody>
  )
}

Body.displayName = 'Table.Body'

export default Body
