import { cn } from '../../helpers/tailwind'
import { Image } from './image'
import { useUi, type Breakpoints } from './use-ui'
import { ImageProps } from '.'
import ThumbIcon from '@/images/icon.svg?svgr'

export type IconSizes = 'medium' | 'large' | 'xl'
export type IconPosition = 'top' | 'center' | 'baseline'

export type ListItemProps = {
  icon?: ImageProps['src'] | boolean
  iconPosition?: IconPosition
  iconSize?: Breakpoints<IconSizes>
  itemClassName?: string
  children: React.ReactNode
  className?: string
  id?: string
}

export const ListItem = ({
  itemClassName = '',
  className,
  icon = false,
  iconSize = 'medium',
  iconPosition = 'top',
  children,
  id,
}: ListItemProps) => {
  const iconSizes = {
    default: 'shrink-0',
    medium: 'h-5 w-5', //Tailwind sm:h-5 sm:w-5  md:h-5 md:w-5  lg:h-5 sm:w-5  lg:h-5 sm:w-5  xl:h-5 xl:w-5
    large: 'h-7 w-7', //Tailwind sm:h-7 sm:w-7  md:h-7 md:w-7  lg:h-7 sm:w-7  lg:h-7 sm:w-7  xl:h-7 xl:w-7
    xl: 'h-10 w-10', //Tailwind sm:h-10 sm:w-10  md:h-10 md:w-10  lg:h-10 sm:w-10  lg:h-10 sm:w-10  xl:h-10 xl:w-10
  }

  const iconPositions = {
    top: 'items-start',
    center: 'items-center',
    baseline: 'items-baseline',
  }

  const ui = useUi({
    styles: {
      iconSize: { options: iconSizes, selected: iconSize },
    },
    name: 'ListItem',
    className,
  })

  return icon ? (
    <li className={`mt-1 flex ${iconPositions[iconPosition]}`} id={id || 'list-item'}>
      <div className={ui.className}>
        {typeof icon === 'object' ? (
          <Image src={icon} alt="Bullet Icon" className={cn({ 'pt-0.5': iconPosition === 'top' })} />
        ) : (
          <ThumbIcon className={cn({ 'pt-0.5': iconPosition === 'top' })} />
        )}
      </div>
      <span className={`${itemClassName} ml-2`}>{children}</span>
    </li>
  ) : (
    <li className={itemClassName} id={id || 'list-item'}>
      {children}
    </li>
  )
}

export default ListItem
