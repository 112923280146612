import * as React from 'react'
import List from '../list'
import ListItem from '../list-item'

export type ULProps = { children: React.ReactNode }
export type OLProps = { children: React.ReactNode }
export const UL = ({ children }: ULProps) => <List>{children}</List>
export const OL = ({ children }: OLProps) => <List listStyle="number">{children}</List>
export type LIProps = { children: React.ReactNode }
export const LI = ({ children }: LIProps) => <ListItem itemClassName="text-lg lg:text-xl">{children}</ListItem>
