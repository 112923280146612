import { stateBGColors } from '../chart-colors'
import { useUi, Breakpoints } from '../use-ui'
import { TableBackgroundColors, useTable } from './table'

export type GridColVerticalAligns = 'top' | 'middle' | 'bottom'

export type RowProps = {
  colVerticalAlign?: Breakpoints<GridColVerticalAligns>
  backgroundColor?: TableBackgroundColors
  className?: string
  children: React.ReactNode
} & Omit<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>,
  'className' | 'children'
>

export const Row = ({ colVerticalAlign, backgroundColor, className, children, ...props }: RowProps) => {
  const { backgroundColor: tableBackgroundColor } = useTable()

  backgroundColor = backgroundColor || tableBackgroundColor

  const backgroundColors: { [key in TableBackgroundColors]: string } = {
    transparent: '',
    alternate: 'even:bg-selphWhite-200',
    ...stateBGColors,
  }

  const colVerticalAligns: { [key in GridColVerticalAligns]: string } = {
    top: 'align-top', // Tailwind: sm:align-top  md:align-top  lg:align-top  xl:align-top
    middle: 'align-middle', // Tailwind: sm:align-middle  md:align-middle  lg:align-middle  xl:align-middle
    bottom: 'align-bottom', // Tailwind: sm:align-bottom  md:align-bottom  lg:align-bottom  xl:align-bottom
  }

  const ui = useUi({
    styles: {
      colVerticalAlign: { options: colVerticalAligns, selected: colVerticalAlign },
      backgroundColor: { options: backgroundColors, selected: backgroundColor },
    },
    className: className,
    name: 'Table.Row',
  })

  return (
    <tr {...props} className={ui.className}>
      {children}
    </tr>
  )
}

Row.displayName = 'Table.Row'

export default Row
