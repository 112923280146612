import { stateBGColors } from '../chart-colors'
import { useUi } from '../use-ui'
import { TableBackgroundColors, TableBorderColors, TableBorders, useTable } from './table'

export type HeadBorders = 'none' | 'y'

export type HeadProps = {
  className?: string
  children: React.ReactNode
} & Omit<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>,
  'className' | 'children'
>

export const Head = ({ children, className, ...props }: HeadProps) => {
  const { backgroundColor, border, borderColor } = useTable()

  const borders: { [key in TableBorders]: string } = {
    none: '',
    full: '',
    inner: '',
    bottom: '',
    minimal: 'border-y-2',
  }

  const backgroundColors: { [key in TableBackgroundColors]: string } = {
    transparent: '',
    alternate: 'bg-selphWhite-200',
    ...stateBGColors,
  }

  const borderColors: { [key in TableBorderColors]: string } = {
    dark: 'border-selphBlack/50',
    light: 'border-selphWhite-600',
  }

  const ui = useUi({
    styles: {
      backgroundColor: { options: backgroundColors, selected: backgroundColor },
      border: { options: borders, selected: border },
      borderColors: { options: borderColors, selected: borderColor },
    },
    name: 'Table.Head',
    className: className,
  })

  return (
    <thead className={ui.className} {...props}>
      {children}
    </thead>
  )
}

Head.displayName = 'Table.Head'

export default Head
