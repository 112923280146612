import * as React from 'react'
import { omit } from 'lodash-es'
import * as UI from '@/ui'

export type MediaComponentPropsImage = UI.ImageProps
export type MediaComponentPropsVideo = React.DetailedHTMLProps<
  React.IframeHTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
>

export type MediaComponentResult = MediaComponentResultImage | MediaComponentResultVideo
export type MediaComponentResultVideo = {
  type: 'video'
  Component: React.FunctionComponent<MediaComponentPropsVideo>
  props: MediaComponentPropsVideo
}
export type MediaComponentResultImage = {
  type: 'image'
  Component: typeof UI.Image
  props: MediaComponentPropsImage
}

export const mediaComponent = (
  htmlAttributes: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
): MediaComponentResult => {
  let props:
    | React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    | MediaComponentPropsVideo = omit(htmlAttributes, 'node')

  if (/^[/a-zA-Z0-9_-]+\.(jpg|git|jpeg|png|webp)/.test(htmlAttributes?.src || '')) {
    return {
      type: 'image',
      Component: UI.Image,
      props: { ...props, src: `/images/${htmlAttributes.src?.replace(/^\//, '')}` } as MediaComponentPropsImage,
    }
  } else {
    // move alt tag to title tag
    props = {
      ...omit(props, 'alt'),
      title: (props as MediaComponentPropsImage).alt,
    } as MediaComponentPropsVideo

    if (/youtube\.com/.test(htmlAttributes.src || '')) {
      // convert watch urls to embded urls
      const src = htmlAttributes.src?.replace(/\/watch\?v=([^&]+)(.*)/, '/embed/$1?$2')

      props = {
        ...props,
        src,
        frameBorder: 0,
        allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        allowFullScreen: true,
      } as MediaComponentPropsVideo

      const Component: React.FunctionComponent<MediaComponentPropsVideo> = (props) => (
        <iframe {...omit(props, ['children', 'title'])} title={props.title} className="aspect-video h-auto w-full">
          {props.children}
        </iframe>
      )

      return {
        type: 'video',
        Component,
        props,
      }
    } else {
      throw new Error('UNSUPPORTED MEDIA TYPE')
    }
  }
}

export const Media = (
  htmlAttributes: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
  const { Component, props, type } = mediaComponent(htmlAttributes)

  switch (type) {
    case 'image':
      return (
        <span className="relative block">
          <Component {...props} />
        </span>
      )
    case 'video':
      return <Component {...props} />
  }
}
