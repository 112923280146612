import { AnalysisFragment, RangeType } from '@/gql'

export type StateBGColors = keyof typeof stateBGColors

export const colors = {
  LOW_RED: '#C97076',
  HIGH_RED: '#C97076',
  LOW: '#DD9F07',
  HIGH: '#DD9F07',
  POSITIVE: '#DD9F07',
  WEAK_POSITIVE: '#DD9F07',
  NORMAL: '#537DAD',
  NEGATIVE: '#537DAD',
  OPTIMAL: '#99B1D8',
  EQUIVOCAL: '',
  UNKNOWN: '',
  MAX: '',
  MIN: '',
}

export const resultColorPicker = (
  level: RangeType | null | undefined,
  analysis?: AnalysisFragment | undefined,
): StateBGColors | undefined => {
  if (analysis) {
    if (!analysis.analysisDefinition) {
      return
    }
    if (analysis.valueText === 'Pending') {
      return
    }
  }

  switch (level) {
    case RangeType.LowRed:
    case RangeType.HighRed:
      return 'danger'
    case RangeType.Low:
    case RangeType.High:
    case RangeType.Positive:
    case RangeType.Equivocal:
    case RangeType.WeakPositive:
      return 'warning'
    case RangeType.Optimal:
      return 'optimal'
    case null:
    case undefined:
      return 'highlight'
    default:
      return 'normal'
  }
}

export const stateBGColors = {
  danger: 'bg-[#C97076]',
  warning: 'bg-[#DD9F07]',
  info: 'bg-selphTeal-100/50',
  optimal: 'bg-[#99B1D8]',
  normal: 'bg-[#537DAD]',
  highlight: 'bg-selphOrange-200',
}
