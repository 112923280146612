import { useUi, type Breakpoints } from '../use-ui'
import { stateBGColors } from '../chart-colors'
import { useTable } from './table'

import { sizes, aligns, DAligns, DTextColors } from './d'

export type HAligns = DAligns
export type HTextColors = DTextColors

export type HProps = {
  align?: Breakpoints<HAligns>
  className?: string
  children?: React.ReactNode
  textColor?: HTextColors
} & Omit<
  React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>,
  'className' | 'align'
>

export const H = ({ align = 'start', className, children, textColor, ...props }: HProps) => {
  const { size, backgroundColor } = useTable()

  const textColors = {
    default: 'font-semibold',
    alternate: '',
    black: 'text-selphBlack',
    white: 'text-selphWhite-500',
    transparent: 'text-selphBlack',
    ...stateBGColors,
  }

  const ui = useUi({
    styles: {
      align: { options: aligns, selected: align },
      textColors: { options: textColors, selected: textColor || backgroundColor },
      sizes: { options: sizes, selected: size },
    },
    name: 'Table.H',
    className,
  })

  return (
    <td {...props} className={ui.className}>
      {children}
    </td>
  )
}

H.displayName = 'Table.H'

export default H
