import * as React from 'react'
import { HProps } from '../table/h'
import * as UI from '@/ui'

export const Table = ({ children }: { children: React.ReactNode }) => (
  <UI.Table.Table size="small" backgroundColor="transparent" border="minimal" className="mx-auto mt-5 max-w-fit">
    {children}
  </UI.Table.Table>
)

export const Body = ({ children }: { children: React.ReactNode }) => <UI.Table.Body>{children}</UI.Table.Body>

export const Head = ({ children }: { children: React.ReactNode }) => <UI.Table.Head>{children}</UI.Table.Head>

export const Row = ({ children }: { children: React.ReactNode }) => <UI.Table.Row>{children}</UI.Table.Row>

export const HeadCell = ({
  children,
  colSpan,
  className,
  alignment,
}: {
  children: React.ReactNode
  colSpan?: number
  className?: string
  alignment?: HProps['align']
}) => {
  return (
    <UI.Table.H colSpan={colSpan} className={className} align={alignment}>
      {children}
    </UI.Table.H>
  )
}

export const DataCell = ({
  children,
  colSpan,
  className,
  alignment,
}: {
  children: React.ReactNode
  colSpan?: number
  className?: string
  alignment?: HProps['align']
}) => (
  <UI.Table.D colSpan={colSpan} className={className} align={alignment}>
    {children}
  </UI.Table.D>
)
