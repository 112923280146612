import { createContext, useContext } from 'react'
import { Breakpoints, useUi } from '../use-ui'
import { StateBGColors } from '../chart-colors'

export type TableSizes = 'xs' | 'small' | 'medium' | 'large' | 'xl'
export type TableBorderColors = 'dark' | 'light'

export type TableBackgroundColors = 'transparent' | 'alternate' | StateBGColors
export type TableDivideColors = 'transparent' | 'dark' | 'light'
export type TableBorders = 'none' | 'minimal' | 'full' | 'inner' | 'bottom'

export type TableContextType = {
  size: Breakpoints<TableSizes>
  border: TableBorders
  borderColor: TableBorderColors
  backgroundColor?: TableBackgroundColors
  divideColor?: TableDivideColors
}

export const TableContext = createContext<TableContextType>({
  size: 'medium',
  border: 'full',
  borderColor: 'dark',
  backgroundColor: 'transparent',
  divideColor: 'dark',
})

export type TableProps = {
  size?: Breakpoints<TableSizes>
  border?: TableBorders
  borderColor?: TableBorderColors
  backgroundColor?: TableBackgroundColors
  divideColor?: TableDivideColors
  children: React.ReactNode
  className?: string
} & Omit<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>,
  'className' | 'children' | 'border'
>

export const useTable = () => useContext<TableContextType>(TableContext)

export const Table = ({
  size = 'medium',
  border = 'full',
  borderColor = 'light',
  divideColor = 'transparent',
  backgroundColor = 'transparent',
  className,
  children,
  ...props
}: TableProps) => {
  const sizes: { [key in TableSizes]: string } = {
    xs: 'text-xs',
    small: 'text-sm',
    medium: 'text-base',
    large: '',
    xl: '',
  }

  const borders: { [key in TableBorders]: string } = {
    none: 'border-none',
    minimal: 'border-b-2',
    full: `border overflow-hidden rounded-xl`,
    bottom: 'border-b-2',
    inner: '',
  }

  const borderColors: { [key in TableBorderColors]: string } = {
    dark: 'border-selphBlack/50',
    light: 'border-selphWhite-600',
  }

  const divideColors: { [key in TableDivideColors]: string } = {
    transparent: '',
    dark: 'divide-y-2 divide-selphBlack/50',
    light: 'divide-y-2 divide-selphWhite-500',
  }

  const divUi = useUi({
    styles: {
      borders: { options: borders, selected: border },
      borderColors: { options: borderColors, selected: borderColor },
    },
    name: 'div',
    className: `${className} overflow-x-scroll`,
  })

  const tableUi = useUi({
    styles: {
      sizes: { options: sizes, selected: size },
      divideColors: { options: divideColors, selected: divideColor },
    },
    name: 'Table',
    className: 'min-w-full',
  })

  return (
    <TableContext.Provider value={{ size, border, borderColor, backgroundColor, divideColor }}>
      <div className={divUi.className}>
        <table className={tableUi.className} {...props}>
          {children}
        </table>
      </div>
    </TableContext.Provider>
  )
}

Table.displayName = 'Table.Table'

export default Table
